<template>
    <div class="doneOrderList">
        <div class="log-card">
            <div class="log-card-top">
                <div class="log-card-top-text">
                    <div>
                        <div class="can_with_draw">可提现金额</div>
                        <div class="can_with_draw_money">¥<span style="font-size: 18px;">{{moneyVal}}</span></div>
                    </div>
                    <div @click="withLogList" class="card-button">提现记录</div>
                </div>
            </div>

            <div style="position: absolute;top: 103px">
                <div class="log-card-desc">
                    <div class="log-card-desc-one">可提现金额<span
                            style="font-size: 12px;color: #979797; margin-left:10px">提现最小额度为￥1.00</span></div>
                    <div class="log-card-desc-two">
                        <div style="width:10%;font-size: 30px;font-weight: 600;">￥</div>
                        <div style="flex:1">
                            <input ref="inputs" v-model="outVal" type="text" @blur=""
                                   style="text-align: left; height: 100%;width:100%;outline: none;border: none;font-size: 30px"/>
                        </div>
                        <div @click="outAllMoney"
                             style="font-weight: 400;color: #F46A17;font-size: 14px;text-align: right;">全部提现
                        </div>
                    </div>
                    <div class="log-card-desc-three">
                        <div>提现至</div>
                        <div><img style="width:18px;vertical-align:middle;"
                                  :src="require('@STATIC/image/icons/s-ye.png')"/> 微信零钱
                        </div>
                    </div>
                    <div class="log-card-desc-four" @click="makeOutMoney">立即提现</div>
                    <div class="log-card-desc-five">*温馨提示：每周六可申请提现，审核周期为1-7个工作日</div>
                </div>

                <div class="log-card-desc-bottom"></div>

                <div class="log-card-desc-box"></div>

            </div>

        </div>
    </div>
</template>

<script>
  export default {
    name: 'with-drawal',
    data () {
      return {
        moneyVal: 0, //可提现金额
        outVal: 0, //提现金额
        isLoading: false,//是否正在执行提现
      }
    },
    mounted () {
      document.title = '自提点佣金提现'
      this.CanWithDraw()
    },
    methods: {
      //全部提出
      outAllMoney () {
        this.outVal = this.moneyVal
      },

      withLogList () {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/selfExtract/with-drawal-log',
          })
        } else {
          this.$router.push({
            path: 'with-drawal-log',
          })
        }
      },

      //可提现金额
      async CanWithDraw () {
        try {
          let query = await this.$api.community.points.CanWithDraw()
          this.moneyVal = query.data
          this.outVal = query.data

        } catch (error) {
          console.log('dataInfo error', error)
        }
      },

      async makeOutMoney () {
        if (this.outVal > 0 && this.moneyVal > 0 && this.outVal <= this.moneyVal) {
          try {
            if (this.isLoading == true) {
              return
            }

            let params = {
              outVal: this.outVal,
            }
            this.isLoading = true
            let query = await this.$api.community.points.doWithDraw(params)
            this.$toast.success('申请成功')
            setTimeout(() => {
              this.CanWithDraw()
              this.isLoading = false
            }, 1000)

          } catch (error) {
            this.$toast.fail(error.message)
            this.isLoading = false
          }
        } else {
          this.$toast.text('请输入合法的提现金额')
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    div {
        border: 0px solid red;
    }

    @include b(doneOrderList) {
        width: 100vw;
        height: 100vh;
        background: #F8F8F8;
        display: flex;
        flex-direction: column;
        position: relative;
        .log-card {
            background: #F5F5F5;
            margin-bottom: 8px;
            padding-bottom: 8px;
            width: 100%;
            border: 0px solid red;
            height: 100%;

            .log-card-top {
                height: 166px;
                background: #F46A17;
                padding: 15px 15px;
                border: 0px solid red;

                .log-card-top-text {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 70px;
                    text-align: center;
                    align-items: center;
                    color: #FFFFFF;
                    font-family: PingFangSC-Medium, PingFang SC;
                    .can_with_draw {
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 26px;
                    }
                    .can_with_draw_money {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 26px;
                    }
                }

                .card-button {
                    width: 79px;
                    height: 30px;
                    background: #FFFFFF;
                    border-radius: 15px;
                    opacity: 0.8;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #F46A17;
                    line-height: 30px;
                }

            }

            .log-card-desc {
                width: 345px;
                background: #FFFFFF;
                box-shadow: 0px 1px 6px 0px rgba(215, 215, 215, 0.5);
                border-radius: 8px;
                margin-left: 15px;
                font-family: PingFangSC-Medium, PingFang SC;

                .log-card-desc-one {
                    padding-left: 15px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #303030;
                }
                .log-card-desc-two {
                    padding: 0 15px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #303030;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #F2F2F2;
                }
                .log-card-desc-three {
                    padding: 0 15px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #303030;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 79px;

                }
                .log-card-desc-four {

                    width: 225px;
                    height: 50px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    box-shadow: 0px 1px 7px 0px rgba(244, 106, 23, 0.5);
                    border-radius: 27px;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 50px;
                    margin: auto;
                    text-align: center;

                }
                .log-card-desc-five {

                    padding: 0 15px;
                    height: 25px;
                    font-size: 11px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #979797;
                    line-height: 25px;
                    height: 50px;
                    line-height: 50px;

                }

            }
            .log-card-desc-bottom {
                width: 345px;
                height: 10px;
            }
            .log-card-desc-box {
                margin-left: 15px;
                width: 345px;
                height: 230px;
                background: #FFFFFF;
                box-shadow: 0px 1px 6px 0px rgba(215, 215, 215, 0.5);
                border-radius: 8px;

            }

        }

    }

</style>